import React, { useState } from "react";
import { AiOutlineLogin, AiOutlineRollback } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const [isPasswordForgotten, setIsPasswordForgotten] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [username, setUsername] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [usernameError, setUsernameError] = useState("");
  // const [loginError, setLoginError] = useState("");

  const navigate = useNavigate();

  const onPasswordForgotten = (event) => {
    event.preventDefault();
    setUsernameError("");
    setIsPasswordForgotten(!isPasswordForgotten);
  };

  const onUsernameChange = (event) => {
    setUsername(event.target.value);
    setUsernameError("");
  };

  const onEmailChange = (event) => {
    setEmailInput(event.target.value);
    setEmailError("");
  };

  const onPasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const validateusername = (username) => {
    const regex = /^[a-zA-Z0-9._]+@[a-zA-Z]+\.[a-zA-Z]{2,}$/;
    if (!regex.test(username) || username.includes(" ")) {
      return false;
    }
    return true;
  };

  const validateEmail = (emailInput) => {
    const regex = /^[a-zA-Z0-9._]+@[a-zA-Z]+\.[a-zA-Z]{2,}$/;
    if (!regex.test(emailInput) || emailInput.includes(" ")) {
      return false;
    }
    return true;
  };

  // Api Integration with Jwt Token ---
  const onSignIn = async () => {
    let data;
    try {
      const response = await fetch("https://artistaloud-backend.kollywoodhungama.com/v1/CMS/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: username,
          password: password,
        }),
        
      });

      data = await response.json();
      console.log(data);
      if (response.ok) {
        const { token, message } = data;
    //     localStorage.setItem("jwtToken", token);
        

    //     navigate("/home");
    //     toast.success(message);
    //   } else {
    //     toast.error(data.message);
    //   }
    // } catch (error) {
    //   toast.error(data.message);
    // }

      localStorage.setItem("login", true);  // Store login state
      navigate("/home");  // Redirect to home page
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    toast.error("An error occurred");
  }
  };

  const onSubmit = () => {
    if (!validateEmail(emailInput)) {
      setEmailError("Invalid Email address");
    } else {
      setEmailError("");
      setIsSubmitted(true);
      toast.success(
        "Your password has been reset. Please check your email for the new password."
      );
      setTimeout(() => {
        setIsSubmitted(false);
        setIsPasswordForgotten(false);
      }, 4000);
    }
  };

  const onBackClick = () => {
    setIsPasswordForgotten(!isPasswordForgotten);
    setUsername("");
    setUsernameError("");
  };
  return (
    <>
      <div className="flex bg-[#100e0e] w-[100%] h-[100vh]">
        <div
          className=" flex items-center justify-center w-[50%] h-[100%] bg-cover bg-center"
          style={{
            backgroundImage: 'url("./assets/cmsLoginBg.jpg")',
          }}
        >
          <img
            src="./assets/aa_logo.png"
            className="w-[auto] h-[55vh]"
            alt=""
          />
        </div>

        {isPasswordForgotten ? (
          <div className="flex flex-col items-center justify-center w-[50%] h-[100%]">
            <h1 className="text-[#FFFFFF] text-[1.7rem] font-bold">
              Forgot Password?
            </h1>
            <br />
            <h2 className="text-white text-[0.8rem]">
              Enter your Email Address below to reset your password
            </h2>
            <br />
            <br />
            <div className="w-[20rem]">
              <label htmlFor="" className="text-white text-[1rem]">
                Email Address
              </label>
            </div>
            <input
              type="username"
              className="w-[20rem] h-[3rem] rounded-[3px] mt-[0.6rem] bg-[#d2d2d2] pl-[0.8rem] outline-none"
              placeholder="Email address"
              value={emailInput}
              onChange={onEmailChange}
            />
            {emailError && (
              <p className="text-red-400 mt-[0.4rem]">{emailError}</p>
            )}
            <br />
            <div className="w-[20rem] flex items-center justify-between">
              <button
                className="flex items-center justify-center text-white text-[0.8rem] w-[8rem] h-[3rem] rounded-[5px] mt-[0.4rem] bg-[#302F2F] hover:bg-[#2c2c2c] active:bg-[#2c2c2c]"
                onClick={onBackClick}
              >
                Back{" "}
                <AiOutlineRollback size={20} style={{ marginLeft: "0.5rem" }} />
              </button>
              <button
                className="flex items-center justify-center text-white text-[0.8rem] w-[8rem] h-[3rem] rounded-[5px] mt-[0.4rem] bg-[#FF6C2F] hover:bg-[#ed632a] active:bg-[#c75525]"
                onClick={onSubmit}
              >
                Submit
              </button>
            </div>
            <br />
            <br />
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center w-[50%] h-[100%]">
            <h1 className="text-[#FF6C2F] text-[1.9rem] font-bold">Sign in</h1>
            <br />
            <h2 className="text-white text-[1rem]">Hungama Artist Aloud CMS</h2>
            <br />
            <div className="w-[20rem]">
              <label htmlFor="" className="text-white text-[1rem]">
                Username
              </label>
            </div>
            <input
              type="text"
              className="w-[20rem] h-[3rem] rounded-[3px] mt-[0.6rem] bg-[#d2d2d2] pl-[0.8rem] outline-none"
              placeholder="Username"
              value={username}
              onChange={onUsernameChange}
            />
            {usernameError && (
              <p className="text-red-400 mt-[0.5rem]">{usernameError}</p>
            )}
            <br />
            <div className="w-[20rem]">
              <label htmlFor="" className="text-white text-[1rem]">
                Password
              </label>
            </div>
            <input
              type="password"
              className="w-[20rem] h-[3rem] rounded-[3px] mt-[0.4rem] bg-[#d2d2d2] pl-[0.8rem] outline-none"
              placeholder="Password"
              value={password}
              onChange={onPasswordChange}
            />
            <br />
            <button
              className="flex items-center justify-center text-white text-[0.8rem] w-[7rem] h-[2.5rem] rounded-[5px] mt-[0.4rem] bg-[#FF6C2F]"
              onClick={onSignIn}
            >
              Sign in{" "}
              <AiOutlineLogin size={20} style={{ marginLeft: "0.5rem" }} />
            </button>
            <a
              href=""
              className="text-[#a9a9a9] text-[0.8rem] mt-[0.8rem]"
              onClick={onPasswordForgotten}
            >
              Forgot Password?
            </a>
          </div>
        )}
      </div>
      <ToastContainer />
    </>
  );
}
