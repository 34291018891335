import React, { useState } from "react";
import LanguageDropdown from "./LanguageDropdown";
import SubGenreDropdown from "./SubGenreDropdown";
import KeywordDropdown from "./KeywordDropdown";

export default function Popup(props) {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <div>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-[#161616] bg-opacity-70">
          <div className="bg-[#555555] p-4 rounded-md w-full max-w-screen-lg h-[40vh] overflow-x-auto">
            <table className="w-full min-w-max bg-gray-700 text-white border border-gray-600 rounded-md">
              <thead className="bg-[#252525]">
                <tr>
                  <th className="autnh-1 rounded-l-md border-r border-gray-600">
                    LANGUAGE
                  </th>
                  <th className="autnh-1 border-r border-gray-600">GENRE</th>
                  <th className="autnh-1 border-r border-gray-600">
                    SUB GENRE
                  </th>
                  <th className="autnh-1 border-r border-gray-600">MOOD</th>
                  <th className="autnh-1 border-r border-gray-600">LYRICIST</th>
                  <th className="autnh-1 border-r border-gray-600">
                    MUSIC DIRECTOR
                  </th>
                  <th className="autnh-1 border-r border-gray-600">SINGER</th>
                  <th className="autnh-1 border-r border-gray-600">PRODUCER</th>
                  <th className="autnh-1 border-r border-gray-600">
                    DESCRIPTION
                  </th>
                  <th className="autnh-1 border-r border-gray-600">TAGS</th>
                  <th className="autnh-1 border-r border-gray-600">OTHERS</th>
                  <th className="autnh-1 border-r border-gray-600">
                    COPY RIGHTS
                  </th>
                  <th className="autnh-1 border-r border-gray-600">
                    PUBLISHING RIGHTS
                  </th>
                  <th className="autnh-1 border-r border-gray-600">KEYWORDS</th>
                  {props.type === "video" && (
                    <th className="autnh-1 border-r border-gray-600">
                      RATINGS
                    </th>
                  )}
                  <th className="autnh-1 rounded-r-md">TRACK NAME</th>
                </tr>
              </thead>
              <tbody>
                <tr className="h-0.5 bg-transparent"></tr>
              </tbody>
              <tbody>
                <tr>
                  <td className="autnr rounded-l-md border-r border-gray-600">
                    <LanguageDropdown />
                  </td>
                  <td className="autnr border-r border-gray-600">
                    <div>
                      <select
                        className="single-dropdown rounded-[5px] bg-[#4c4c4c] w-[8rem] h-auto mt-[1%] text-[#FFFFFF] font-[Poppins] p-[2%]"
                        style={{ outlineStyle: "none" }}
                      >
                        <option value="Acoustic">Acoustic</option>
                        <option value="Anthem">Anthem</option>
                        <option value="Bhajan">Bhajan</option>
                        <option value="Bhangra">Bhangra</option>
                        <option value="Bharatanatyam">Bharatanatyam</option>
                        <option value="Bhavageete">Bhavageete</option>
                        <option value="Blues">Blues</option>
                        <option value="Carnatic music">Carnatic music</option>
                        <option value="Chaiti">Chaiti</option>
                        <option value="Classical">Classical</option>
                        <option value="Comedy">Comedy</option>
                        <option value="Contemperory">Contemperory</option>
                        <option value="Country">Country</option>
                        <option value="Dadra">Dadra</option>
                        <option value="Devotional">Devotional</option>
                        <option value="Devotional song">Devotional song</option>
                        <option value="Dhamar">Dhamar</option>
                        <option value="Dhrupad">Dhrupad</option>
                        <option value="EDM">EDM</option>
                        <option value="Filmi">Filmi</option>
                        <option value="Filmi qawwali">Filmi qawwali</option>
                        <option value="Folk music">Folk music</option>
                        <option value="Funk">Funk</option>
                        <option value="Fusion">Fusion</option>
                        <option value="Gaana">Gaana</option>
                        <option value="Ghazal">Ghazal</option>
                        <option value="Hard Rock">Hard Rock</option>
                        <option value="Haveli Sangeet">Haveli Sangeet</option>
                        <option value="Hindi cinema">Hindi cinema</option>
                        <option value="Hindustani classical music">
                          Hindustani classical music
                        </option>
                        <option value="Hip-Hop">Hip-Hop</option>
                        <option value="Hori">Hori</option>
                        <option value="Indian hip hop">Indian hip hop</option>
                        <option value="Indian pop">Indian pop</option>
                        <option value="Indian rock">Indian rock</option>
                        <option value="Inspirational">Inspirational</option>
                        <option value="Khyal">Khyal</option>
                        <option value="Light music">Light music</option>
                        <option value="Mand">Mand</option>
                        <option value="Music in ancient India">
                          Music in ancient India
                        </option>
                        <option value="Music of Punjab">Music of Punjab</option>
                        <option value="Music of Rajasthan">
                          Music of Rajasthan
                        </option>
                        <option value="Odissi music">Odissi music</option>
                        <option value="Patriotic">Patriotic</option>
                        <option value="Poetry">Poetry</option>
                        <option value="Pop">Pop</option>
                        <option value="Qawwali">Qawwali</option>
                        <option value="R&B">R&B</option>
                        <option value="Rabindra Sangeet">
                          Rabindra Sangeet
                        </option>
                        <option value="Raga">Raga</option>
                        <option value="Raga rock">Raga rock</option>
                        <option value="Rap">Rap</option>
                        <option value="Rock">Rock</option>
                        <option value="Romantic">Romantic</option>
                        <option value="Sadra">Sadra</option>
                        <option value="Semi-classical music">
                          Semi-classical music
                        </option>
                        <option value="Sufi">Sufi</option>
                        <option value="Sufi music">Sufi music</option>
                        <option value="Sufi rock">Sufi rock</option>
                        <option value="Sugama Sangeetha">
                          Sugama Sangeetha
                        </option>
                        <option value="Tappa">Tappa</option>
                        <option value="Tarana">Tarana</option>
                        <option value="Thumri">Thumri</option>
                        <option value="Trance">Trance</option>
                        <option value="Trap">Trap</option>
                        <option value="World music">World music</option>
                      </select>
                    </div>
                  </td>
                  <td className="autnr border-r border-gray-600">
                    <SubGenreDropdown />
                  </td>

                  <td className="autnr border-r border-gray-600">
                    <select
                      className="rounded-[5px] bg-[#4c4c4c] w-[8rem] h-auto mt-[1%] text-[#FFFFFF] font-[Poppins] p-[2%]"
                      style={{ outlineStyle: "none" }}
                    >
                      <option value="Happy">Happy</option>
                      <option value="Sad">Sad</option>
                      <option value="Exuberant">Exuberant</option>
                      <option value="Energetic">Energetic</option>
                      <option value="Frantic">Frantic</option>
                      <option value="Anxious">Anxious</option>
                      <option value="Calm">Calm</option>
                      <option value="Contentment">Contentment</option>
                    </select>
                  </td>
                  <td className="autnr border-r border-gray-600">
                    <input
                      className="rounded-[5px] bg-[#4c4c4c] w-[8rem] h-[1.4rem] mt-[1%] text-[#FFFFFF] font-[Poppins] pl-[6px]"
                      type="text"
                      name=""
                      id=""
                      style={{ outlineStyle: "none" }}
                    />
                  </td>
                  <td className="autnr border-r border-gray-600">
                    <input
                      className="rounded-[5px] bg-[#4c4c4c] w-[8rem] h-[1.4rem] mt-[1%] text-[#FFFFFF] font-[Poppins] pl-[6px]"
                      type="text"
                      name=""
                      id=""
                      style={{ outlineStyle: "none" }}
                    />
                  </td>
                  <td className="autnr border-r border-gray-600">
                    <input
                      className="rounded-[5px] bg-[#4c4c4c] w-[8rem] h-[1.4rem] mt-[1%] text-[#FFFFFF] font-[Poppins] pl-[6px]"
                      type="text"
                      name=""
                      id=""
                      style={{ outlineStyle: "none" }}
                    />
                  </td>
                  <td className="autnr border-r border-gray-600">
                    <input
                      className="rounded-[5px] bg-[#4c4c4c] w-[8rem] h-[1.4rem] mt-[1%] text-[#FFFFFF] font-[Poppins] pl-[6px]"
                      type="text"
                      name=""
                      id=""
                      style={{ outlineStyle: "none" }}
                    />
                  </td>
                  <td className="autnr border-r border-gray-600">
                    <input
                      className="rounded-[5px] bg-[#4c4c4c] w-[8rem] h-[1.4rem] mt-[1%] text-[#FFFFFF] font-[Poppins] pl-[6px]"
                      type="text"
                      name=""
                      id=""
                      style={{ outlineStyle: "none" }}
                    />
                  </td>
                  <td className="autnr border-r border-gray-600">
                    <input
                      className="rounded-[5px] bg-[#4c4c4c] w-[8rem] h-[1.4rem] mt-[1%] text-[#FFFFFF] font-[Poppins] pl-[6px]"
                      type="text"
                      name=""
                      id=""
                      style={{ outlineStyle: "none" }}
                    />
                  </td>
                  <td className="autnr border-r border-gray-600">
                    <input
                      className="rounded-[5px] bg-[#4c4c4c] w-[8rem] h-[1.4rem] mt-[1%] text-[#FFFFFF] font-[Poppins] pl-[6px]"
                      type="text"
                      name=""
                      id=""
                      style={{ outlineStyle: "none" }}
                    />
                  </td>
                  <td className="autnr border-r border-gray-600">
                    <input
                      className="rounded-[5px] bg-[#4c4c4c] w-[8rem] h-[1.4rem] mt-[1%] text-[#FFFFFF] font-[Poppins] pl-[6px]"
                      type="text"
                      name=""
                      id=""
                      style={{ outlineStyle: "none" }}
                    />
                  </td>
                  <td className="autnr border-r border-gray-600">
                    <input
                      className="rounded-[5px] bg-[#4c4c4c] w-[8rem] h-[1.4rem] mt-[1%] text-[#FFFFFF] font-[Poppins] pl-[6px]"
                      type="text"
                      name=""
                      id=""
                      style={{ outlineStyle: "none" }}
                    />
                  </td>
                  <td className="autnr border-r border-gray-600">
                    <KeywordDropdown />
                  </td>

                  {props.type === "video" && (
                    <td className="autnr border-r border-gray-600">
                      <select
                        className="rounded-[5px] bg-[#4c4c4c] w-[4rem] h-auto mt-[1%] text-[#FFFFFF] font-[Poppins] p-[2%]"
                        style={{ outlineStyle: "none" }}
                      >
                        <option
                          className="hover:bg-[#FF6C2F] active:bg-[#c75525]"
                          value="U"
                        >
                          U
                        </option>
                        <option value="7+">7+</option>
                        <option value="13+">13+</option>
                        <option value="16+">16+</option>
                        <option value="18+">18+</option>
                      </select>
                    </td>
                  )}

                  <td className="autnr rounded-r-md">
                    <input
                      className="rounded-[5px] bg-[#4c4c4c] w-[8rem] h-[1.4rem] mt-[1%] text-[#FFFFFF] font-[Poppins] pl-[6px]"
                      type="text"
                      name=""
                      id=""
                      style={{ outlineStyle: "none" }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="flex justify-end mt-[5vh]">
              <button className="bg-[#131313] text-[0.9rem] text-white rounded-md hover:bg-[#FF6C2F] active:bg-[#c75525] p-[12px]">
                Update
              </button>
              <button
                className=" ml-[10px] bg-[#131313] text-[0.9rem] text-white rounded-md hover:bg-[#252525] active:bg-[#484848] p-[12px]"
                onClick={() => setIsOpen(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
