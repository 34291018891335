import React, { useState, useRef, useEffect } from "react";

const LanguageDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const options = [
    "Hindi",
    "Telugu",
    "Tamil",
    "Bengali",
    "Gujarati",
    "Malayalam",
    "Odia",
    "Urdu",
    "Kannada",
    "Sanskrit",
    "Marathi",
    "Assamese",
    "Punjabi",
    "Nepali",
    "Konkani",
    "Maithili",
    "Dogri",
    "Sindhi",
    "Santali",
    "Manipuri",
    "Bhojpuri",
    "Arabic",
    "Meitei",
    "Chattisgiri",
    "Haryanvi",
  ];

  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const handleDocumentClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  return (
    <div className="custom-multi-select relative" ref={dropdownRef}>
      <div
        className="dropdown-header rounded-[5px] bg-[#4c4c4c] w-[8rem] h-auto mt-[1%] text-[#FFFFFF] font-[Poppins] p-[2%] cursor-pointer"
        onClick={toggleDropdown}
        style={{ outlineStyle: "none" }}
      >
        {selectedOptions.length > 0 ? selectedOptions.join(", ") : "Select..."}
      </div>
      {isOpen && (
        <div className="dropdown-list absolute mt-[1%] w-[8rem] bg-[#4c4c4c] rounded-[5px] text-[#FFFFFF] font-[Poppins] p-[2%] shadow-lg">
          {options.map((option) => (
            <div
              key={option}
              className={`dropdown-item p-[5px] cursor-pointer rounded-md hover:bg-[#6c6c6c] ${
                selectedOptions.includes(option) ? "bg-[#6c6c6c]" : ""
              }`}
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguageDropdown;
