import React, { useState, useRef, useEffect } from "react";

const SubGenreDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const options = [
    "Aarti",
    "Abhang",
    "Acoustic",
    "Acoustic Pop",
    "Alternative",
    "Alternative Metal",
    "Alternative Rock",
    "Anthem",
    "Ashaar",
    "Ballad",
    "Bhajan",
    "Bhakti Sangeet",
    "Bheem Geet",
    "Blues",
    "Bollywood",
    "Carnatic",
    "Carols",
    "Chants",
    "Children",
    "Christian",
    "Classic Rock",
    "Classical",
    "Comedy",
    "Contemporary",
    "Country",
    "Dance",
    "Dance Pop",
    "Devotional",
    "Dharmik Sangeet",
    "Disco",
    "Discourse",
    "Dohe",
    "Dua",
    "Dubstep",
    "Easy Listening",
    "EDM",
    "Electro",
    "Electro House",
    "Electronic Trap",
    "Electronica",
    "Experimental",
    "Festival",
    "Folk",
    "Folk Rock",
    "Funk",
    "Funny",
    "Fusion",
    "Ghazal",
    "Gospel",
    "Grunge",
    "Guru Bani",
    "Hadith Sharif",
    "Hamd",
    "Hard Rock",
    "Hindustani",
    "Hip hop",
    "Hollywood",
    "House",
    "Hymns",
    "Indie",
    "Indie Pop",
    "Indie Rock",
    "Inspirational",
    "Interview",
    "Islamic",
    "Jazz",
    "Kirtan",
    "Lavani",
    "Lokgeet",
    "Lounge",
    "Mantras",
    "Meditation",
    "Mersia",
    "Mixtape",
    "Motivational",
    "Movie Soundtracks",
    "Mushaira",
    "Naad",
    "Naats",
    "Narration",
    "Natak",
    "New Age",
    "Nonstop",
    "Novelty",
    "Oldies",
    "Patriotic",
    "Peaceful",
    "Pop",
    "Qawwali",
    "Rap",
    "Reggae",
    "Remix",
    "Rock",
    "Romantic",
    "Sanskrit",
    "Sufi",
    "Sound Effects",
    "Speech",
    "Spiritual",
    "Sports",
    "Storytelling",
    "Talas",
    "Talk",
    "Techno",
    "Themed Music",
    "Traditional",
    "Trance",
    "Vedic",
    "Wedding",
    "World",
  ];

  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const handleDocumentClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  return (
    <div className="custom-multi-select relative" ref={dropdownRef}>
      <div
        className="dropdown-header rounded-[5px] bg-[#4c4c4c] w-[8rem] max-h-[20vh] mt-[1%] text-[#FFFFFF] font-[Poppins] p-[2%] cursor-pointer"
        onClick={toggleDropdown}
        style={{ outlineStyle: "none" }}
      >
        {selectedOptions.length > 0 ? selectedOptions.join(", ") : "Select..."}
      </div>
      {isOpen && (
        <div className="dropdown-list absolute mt-[1%] w-[8rem] max-h-[60vh] bg-[#4c4c4c] rounded-[5px] text-[#FFFFFF] font-[Poppins] p-[2%] shadow-lg overflow-y-auto">
          {options.map((option) => (
            <div
              key={option}
              className={`dropdown-item p-[5px] cursor-pointer rounded-md hover:bg-[#6c6c6c] ${
                selectedOptions.includes(option) ? "bg-[#6c6c6c]" : ""
              }`}
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SubGenreDropdown;