import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import HomePage from "./HomePage";

// const isAuthenticated = () => {
//   return localStorage.getItem('token') !== null;
// // return true;
// };

const PrivateRoute = ({ element: Component }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const login = localStorage.getItem("login");

    if (!login) {
      navigate("/");
    }
    // Only navigate if on the login page; otherwise, stay on the intended route.
  }, [navigate]);

  // Render the passed component
  return <Component/>;
};

export default PrivateRoute;
